/**
 * isIndexMultiple
 *
 * Check if the index of an item is a multiple of a given number
 *
 * @param {number} index
 * @param {number} modulo
 * @returns {boolean}
 */
export const isIndexMultiple = (index: number, modulo: number): boolean =>
  (index + 1) % modulo === 0;

/**
 * Make delay
 *
 * @param {number} time
 * @returns {object} promise
 */
export const makeDelay = (time: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, time));

/**
 * Flatten array
 * @param {array} array
 */
export const flatten = (array: any[]): any[] =>
  array.reduce(
    (acc, element) =>
      Array.isArray(element)
        ? acc.concat(flatten(element))
        : acc.concat(element),
    []
  );

/**
 * Make size readable
 *
 * @param {number} size
 * @returns {string} size readable
 */

export const makeSizeReadable = (size: number): string => {
  const BYTE_UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const exponent = Math.min(
    Math.floor(Math.log10(size) / 3),
    BYTE_UNITS.length - 1
  );

  const number = (size / 1000 ** exponent).toFixed(2);
  const unit = BYTE_UNITS[exponent];

  return `${number} ${unit}`;
};

/**
 * Get a pseudo unique id (base on Math.random())
 * It is not guaranteed to generate a unique id but it is reliable enough to generate ids on the client side
 */
export const getId = () => {
  const getIdChunk = () => {
    const rd = Math.random();
    return rd === 0 ? rd : (rd * 100000).toString().split('.')[0];
  };

  return `${getIdChunk()}-${getIdChunk()}`;
};

export const withTwoDecimals = (value: number) => Math.round(value * 100) / 100;
